<template>
  <div>
    <web-header borderBottom></web-header>
    <div class="page-article-list page-search">
      <div class="f-wrap">
        <div class="page-header">
          <div class="page-header-en">SEARCH RESULT</div>
          <span class="page-header-cn">搜索结果</span>
        </div>
        <div class="page-search-box">
          <el-button size="default" class="searchall search-btn" slot="reference" @click="showAll">全部</el-button>
          <el-popover
              placement="bottom"
              popper-class="drop-popper"
              trigger="click">
            <div class="drop-popper-wrap">
              <div v-for="(item, index) in yearList" :class="{ 'drop-popper-wrap-item': true, 'active': searchForm.year == item.dict_label }" :key="index" @click="yearTap(item)">{{ item.dict_label }}</div>
            </div>
            <el-button size="default" class="search-item search-btn" slot="reference">
              时间
              <i class="fa fa-list-ul"></i>
            </el-button>
          </el-popover>
          <el-popover
              placement="bottom"
              popper-class="drop-popper"
              trigger="click">
            <div class="drop-popper-wrap">
              <div :class="{ 'drop-popper-wrap-item': true, 'active': searchForm.type == 'title' }" @click="typeTap('title')">标题</div>
              <div :class="{ 'drop-popper-wrap-item': true, 'active': searchForm.type == 'author' }" @click="typeTap('author')">作者</div>
              <div :class="{ 'drop-popper-wrap-item': true, 'active': searchForm.type == 'content' }" @click="typeTap('content')">正文</div>
            </div>
            <el-button size="default" class="search-item search-btn" slot="reference">
              类型
              <i class="fa fa-list-ul"></i>
            </el-button>
          </el-popover>
          <el-popover
              placement="bottom"
              popper-class="drop-popper"
              trigger="click">
            <div class="drop-popper-wrap">
              <div :class="{ 'drop-popper-wrap-item': true, 'active': searchForm.column == 4 }" @click="columnTap(4)">研究院动态</div>
              <div :class="{ 'drop-popper-wrap-item': true, 'active': searchForm.column == 5 }" @click="columnTap(5)">研究成果</div>
              <div :class="{ 'drop-popper-wrap-item': true, 'active': searchForm.column == 6 }" @click="columnTap(6)">学术交流</div>
              <div :class="{ 'drop-popper-wrap-item': true, 'active': searchForm.column == 10 }" @click="columnTap(10)">出版物</div>
            </div>
            <el-button size="default" class="search-item search-btn" slot="reference">
              栏目
              <i class="fa fa-list-ul"></i>
            </el-button>
          </el-popover>
          <el-popover
              placement="bottom"
              popper-class="drop-popper"
              trigger="click">
            <div class="drop-popper-wrap">
              <div v-for="(item, index) in themeList" :class="{ 'drop-popper-wrap-item': true, 'active': searchForm.theme == item.dict_value }" :key="index" @click="themeTap(item)">{{ item.dict_label }}</div>
            </div>
            <el-button size="default" class="search-item search-btn" slot="reference">
              主题
              <i class="fa fa-list-ul"></i>
            </el-button>
          </el-popover>
        </div>
        <div class="page-search-content">
          <div class="page-search-content-item" v-for="(item, index) in dataList" :key="index">
            <a :href="item.url" target="_blank">
              <span v-html="item.title"></span>
            </a>
            <div class="desc">
              <span v-if="item.contentwithouthtml"></span>
              <template v-if="item.contentwithouthtml">
                <span v-if="item.contentwithouthtml.length > 173">
                  {{ item.contentwithouthtml.slice(0,173)+"..." }}
                </span>
                <span v-else>
                  {{ item.contentwithouthtml }}
                </span>
              </template>
              <span v-else>
                {{ item.desc }}
              </span>
            </div>
            <div class="footer">
              <span>{{ dateFormat(item.send_time, 'YYYY-MM-DD') }}</span>
              <div class="depict">
                <span v-if="item.class && item.class.class_name">栏目来源：{{ item.class.class_name }}</span>
                <span v-if="item.author">作者：{{ item.author }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="page-list-pagination" v-if="page.total > page.pageSize">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="page.current"
              :page-size="page.pageSize"
              layout="prev, pager, next, jumper"
              :total="page.total">
          </el-pagination>
        </div>
        <div class="page-list-pagination-btn" v-if="page.total > page.pageSize">
          <el-button type="primary" size="medium" round @click="dataMore" v-if="!noneMore">查看更多</el-button>
          <div class="none-more" v-else>没有更多了</div>
        </div>
      </div>
    </div>
    <web-navigation></web-navigation>
    <web-footer></web-footer>
    <web-right-menu class="right-menu"></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import WebNavigation from "@/components/webNavigation";
import { globalSearch, dictionaryList } from "@/api";
export default {
  name: 'Searchs',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    WebNavigation
  },
  data() {
    return {
      keywords: "",
      // 分页
      page: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 10,
        // 总条数
        total: 0
      },
      // 时间列表
      yearList: [],
      // 主题列表
      themeList: [],
      // 搜索
      searchForm: {
        year: "",
        type: "",
        column: "",
        theme: ""
      },
      dataList: [],
      // 没有更多了
      noneMore: false,
    }
  },
  created() {
    this.keywords = decodeURIComponent(this.$route.params.keywords);
    this.getTableData();
    this.getPopoverList();
  },
  methods: {
    // 获取筛选时间、主题
    async getPopoverList() {
      let param = {
        orderBy: "dict_sort",
        sortedBy: "desc",
        search: "dict_type:zhuti_theme,publication_year",
        searchFields: "dict_type:in"
      };
      let res = await dictionaryList(param);
      if (res && res.code == 200) {
        this.yearList = [];
        this.themeList = [];
        if (res.data && res.data.list && res.data.list.length > 0) {
          for (let i = 0; i < res.data.list.length; i++) {
            let item = res.data.list[i];
            if (item.dict_type == "publication_year") {
              this.yearList.push(item);
            } else {
              this.themeList.push(item);
            }
          }
        }
      }
    },
    // 搜索列表
    async getTableData() {
      let param = {
        pageSize: this.page.pageSize,
        currentPage: this.page.current,
        orderBy: "id",
        sortedBy: "desc",
        search: "",
        searchFields: ""
      };
      if (this.searchForm.year) {
        param.search = `${param.search}|send_time:${this.searchForm.year}-01-01|`;
        param.searchFields = `${param.searchFields}|send_time:>|`
      }
      if (this.searchForm.type && this.searchForm.type == "author") {
        param.search = `${param.search}|author:${this.keywords}|`;
        param.searchFields = `${param.searchFields}|author:like|`
      } else if (this.searchForm.type && this.searchForm.type == "content") {
        param.search = `${param.search}|contentwithouthtml:${this.keywords}|`;
        param.searchFields = `${param.searchFields}|contentwithouthtml:like|`
      } else {
        param['words'] = this.keywords;
      }
      if (this.searchForm.column) {
        param.search = `${param.search}|class_id:${this.searchForm.column}|`;
        param.searchFields = `${param.searchFields}|class_id:=|`
      }
      if (this.searchForm.theme) {
        param.search = `${param.search}|zz_themes:${this.searchForm.theme}|`;
        param.searchFields = `${param.searchFields}|zz_themes:=|`
      }
      let res = await globalSearch(param);
      if (res && res.code == 200) {
        if (res.data.list && res.data.list.length > 0) {
          for (let i = 0; i < res.data.list.length; i++) {
            let item = res.data.list[i];
            item.title = item.title.replace(this.keywords, `<e>${this.keywords}</e>`);
            if (item.model_id == "3") {
              item['url'] = `/book_detail/${item.class_id}/${item.id}`;
            } else if (item.model_id == "2") {
              item['url'] = `/detail/${item.id}`;
            } else {
              item['url'] = `/news_detail/${item.class_id}/${item.id}`;
            }
          }
        }
        this.dataList = res.data.list;
        this.page.total = res.data.total;
      }
    },
    // 查询所有
    showAll() {
      this.searchForm = {
        year: "",
        type: "",
        column: "",
        theme: ""
      };
      this.getTableData();
    },
    // 时间选择
    yearTap(row) {
      if (this.searchForm.year == row.dict_label) {
        this.searchForm.year = "";
      } else {
        this.searchForm.year = row.dict_label;
      }
      this.getTableData();
    },
    // 类型选择
    typeTap(e) {
      if (this.searchForm.type == e) {
        this.searchForm.type = "";
      } else {
        this.searchForm.type = e;
      }
      this.getTableData();
    },
    // 主题选择
    themeTap(row) {
      if (this.searchForm.theme == row.dict_value) {
        this.searchForm.theme = "";
      } else {
        this.searchForm.theme = row.dict_value;
      }
      this.getTableData();
    },
    // 栏目选择
    columnTap(e) {
      if (this.searchForm.column == e) {
        this.searchForm.column = "";
      } else {
        this.searchForm.column = e;
      }
      this.getTableData();
    },
    // 分页，选中的分页
    handleCurrentChange(val) {
      this.page.current = val;
      this.getTableData();
    },
    // 加载更多
    dataMore() {
      let ps = this.page.current + 1;
      if (this.page.total > ps*this.page.pageSize) {
        this.page.current = this.page.current + 1;
        this.getTableData();
      } else {
        this.noneMore = true;
      }
    },
    // 日期格式化
    dateFormat(e, format) {
      return this.$moment(e).format(format);
    },
  }
}
</script>

<style lang="scss" scoped>
.page-search {
  background-color: #f7f7f7;
  .f-wrap {
    width: 802px !important;
    margin: 0 auto;
    padding-top: 75px;
    .page-header {
      text-align: left;
      &-en {
        color: #dae6f9;
        opacity: 0.4;
      }
    }
    .page-search-content {
      margin-top: 20px;
      padding-bottom: 30px;
      &-item {
        width: calc(100% - 60px);
        padding: 30px;
        background-color: #ffffff;
        border-bottom: 1px solid #abc5ee;
        a {
          font-size: 16px;
          color: #333333;
          display: inline-block;
          ::v-deep {
            e {
              color: #004898;
              font-weight: bold;
            }
          }
        }
        .desc {
          color: #707070;
          font-size: 14px;
          margin-top: 12px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .footer {
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .depict {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              &:first-child {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }

}
::v-deep {
  .search-btn {
    padding: 0;
    font-size: 16px;
    font-weight: bold;
    height: 63px;
    background: #004898;
    border-color: #004898;
    line-height: 63px;
    border-radius: 0;
    border-right: 1px solid #abc5ee;
    color: #fff;
  }
  .searchall {
    width: 114px;
  }
  .search-item {
    width: 171px;
    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 25px;
    }
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    background-color: #ffffff !important;
    &:hover {
      color: #ffffff !important;
      background-color: #004898 !important;
    }
  }
  .el-pagination.is-background .btn-next.disabled,
  .el-pagination.is-background .btn-next:disabled,
  .el-pagination.is-background .btn-prev.disabled,
  .el-pagination.is-background .btn-prev:disabled,
  .el-pagination.is-background .el-pager li.disabled {
    background-color: #ffffff !important;
    &:hover {
      color: #C0C4CC !important;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .page-search {
    .f-wrap {
      padding-top: 0px;
      width: calc(100% - 50px) !important;
    }
  }
  ::v-deep {
    .searchall,.search-item {
      width: 20%;
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .page-search {
    .f-wrap {
      padding-top: 0px;
      width: calc(100% - 50px) !important;
      .page-search-content {
        &-item {
          .footer {
            flex-direction: column;
            align-items: flex-start;
            .depict {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
  ::v-deep {
    .search-btn {
      font-size: 14px !important;
      height: 35px !important;
      line-height: 35px !important;
    }
    .searchall,.search-item {
      width: 20%;
    }
    .search-item {
      span {
        padding: 0 5px;
      }
    }
  }
}
</style>